import React, { Component } from 'react'
import Layout from '../../../components/layout'
import { Banner } from '../../../components/banner';
import moment from 'moment';
import Dropzone from "react-dropzone"
import { Cache } from "aws-amplify";
import LeftNavBar from '../../../containers/navbar'
import { navigate } from '@reach/router'
import Query from '../../../containers/netsclick/query';
import { FormGroup, Col, Form, Input, Button, Row, Fade, Card, Label, Badge, ModalBody, ModalFooter, Modal, ModalHeader } from 'reactstrap';
import { isLoggedIn } from '../../../services/auth';
import { UploadDocstoS3, getIntegrationStatus, getSignerInfo, removeSignersCertFile, submitSignerDetailsInfo , validateCertExpiry } from '../../../services/api'
import Merchantcard from '../../../containers/netsclick/merchantcard';
import { ENV_PROD } from '../../../utils/constants';
import errorConstants from '../../../utils/strings'


let that = null
const acceptedFileTypes = ["application/x-x509-ca-cert", "application/pkix-cert"]
const archiveFormats = [
    'application/zip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/gzip',
    'application/x-tar',
    'application/x-bzip2',
    'application/x-xz',
    'application/vnd.ms-cab-compressed'
];


export default class netsClick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetched: true,
            androidBundleID: null,
            noCertReasonInput: null,
            iosBundleID: null,
            useBase: null,
            appFunctionality: null,
            businessNature: null,
            allFilesUploaded: false,
            displayDatePicker: false,
            androidCert: [],
            iosCert: [],
            mtlsCert: [],
            email: "",
            selectedEnv: ENV_PROD,
            appSubmitted: false,
            tempValueExpiryDate: null,
            noAndroidCert: false,
            noIosCert: false,
            profileSavedTime: null,
            CertExpiryInDays : null,
            tempCertFiles : null,
            tempCertType : null,
            showExpiredCertModal : false,
            displayErrorCode : 'NETS_CERT_COMMON_ERROR'
        }
    }
    redirectPage(pageId) {
        navigate(`/product/netsclick/${pageId}`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        } else {
            const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
            this.setState({
                email: (user && user.userData && user.userData.attributes) ? user.userData.attributes.email : ""

            })
        }

    }

    assignValue = event => {
        let id = event.target.id
        this.setState({
            [id]: event.target.value
        }, function () {

            let { androidCert, iosCert, mtlsCert } = this.state
            if (androidCert.length > 0 && iosCert.length > 0 && mtlsCert.length > 0) {
                this.setState({
                    allFilesUploaded: true
                })
            }

        })

    }

    async onDrop(id, validFiles, invalidFiles) {
        let {
            androidCert = [],
            iosCert = [],
            mtlsCert = []
        } = this.state;
        let existingFiles = this.state[id] || [];
        if (validFiles.length > 1) {
            this.setState({ fileUploadError: true, displayErrorCode : 'NETS_CERT_ONE_FILE_UPLOAD_ERROR' });
            return;
        }
        if(invalidFiles.length > 0){
             this.setState({ fileUploadError: true, displayErrorCode : 'NETS_CERT_COMMON_ERROR' });
            return;
        }
        let totalFilesList = existingFiles.length + validFiles.length;
        const invalidFileType = validFiles.every(file =>  archiveFormats.includes(file.type) );
        if(invalidFileType){
            this.setState({ fileUploadError: true, displayErrorCode : 'NETS_CERT_ZIP_FILE_TYPE' });
            return;
        }
        // Check if the total number of files exceeds the limit
        if (totalFilesList > 4) {
            this.setState({ fileUploadError: true, displayErrorCode : 'NETS_CERT_MAX_NO_OF_SIZE_LIMIT' });
            return;
        }

        // Validate file types and set temp property
        const validFileType = validFiles.every(file => acceptedFileTypes.includes(file.type));
        validFiles.forEach(file => {
            file.temp = true;
        });

        const invalidFileError = invalidFiles && invalidFiles.length > 0;

        if (validFiles.length <= 4 && !invalidFileError && validFileType) {
            // Update the state with valid files
            existingFiles.push(...validFiles);
          
            let CertExpiryDate = await validateCertExpiry(validFiles);
            const expiryDateMap = new Map();
            CertExpiryDate.forEach(item => {
                expiryDateMap.set(item.name, item.expiryDate);
            });
    
            // Iterate over existingFiles and update expiryDate where name matches
            existingFiles.forEach(item => {
                if (expiryDateMap.has(item.name)) {
                    item.expiryDate = expiryDateMap.get(item.name);
                }
            });
    
            let diffDays = null;
            let isExpired = false;
            for (let cert of CertExpiryDate) {
                const expiryDate = new Date(cert.expiryDate);
                const diffTime = expiryDate - new Date();
                diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                
                if (diffDays <= 0) {
                    isExpired = true;
                    break;
                }
            }
    
            if (isExpired) {
                this.setState({
                    showExpiredCertModal: true,
                    [id] : [],
                    fileUploadError : false
                    
                });
                return;
            }
    
            const allFilesUploaded = androidCert.length > 0 && iosCert.length > 0 && mtlsCert.length > 0;
    
            this.setState({
                [id]: existingFiles,
                allFilesUploaded,
                fileUploadError: false,
                CertExpiryInDays: diffDays,
                tempCertFiles: diffDays > 90 ? validFiles : null,
                tempCertType: id
            });
        } else {
            this.setState({
                fileUploadError: true,
                displayErrorCode : 'NETS_CERT_COMMON_ERROR',
                allFilesUploaded: false,
                [id]: []
            });
        }
    }
    
    certExpiryAction = (response) => {
        let { tempCertFiles, tempCertType, androidCert = [], iosCert = [], mtlsCert = [] } = this.state;
        if(response == "validCert"){
            this.setState({
                allFilesUploaded: androidCert.length > 0 && iosCert.length > 0 && mtlsCert.length > 0,
                fileUploadError: false,
                CertExpiryInDays: null,
            });
            
        } else if (response == "invalidCert"){
           
            const updatedArray = this.state[tempCertType].filter(item => item.name !== tempCertFiles[0].name);
            
            this.setState({
                [tempCertType]: updatedArray,
                allFilesUploaded: androidCert.length > 0 && iosCert.length > 0 && mtlsCert.length > 0,
                fileUploadError: false,
                CertExpiryInDays: null,
            });

        } else if( response == "expiredCert") {

            this.setState({
                [tempCertType]: [],
                allFilesUploaded: androidCert.length > 0 && iosCert.length > 0 && mtlsCert.length > 0,
                showExpiredCertModal: false,
                CertExpiryInDays: null,
            });
        }
    };
    
    
    setIntOsType(keyID) {

        let keyname = keyID == 'androidCert' ? 'noAndroidCert' : 'noIosCert'

        this.setState(prevState => ({
            [keyname]: !prevState[keyname],
            noCertReasonInput: ''
        }));

    }
    togglePopup(value, fileObj = null, certObj = null) {
        if (fileObj && fileObj.temp) {
            let { androidCert, iosCert, mtlsCert } = this.state
            if (certObj == 'androidCert') {
                androidCert.splice(androidCert.findIndex(file => file.name === fileObj.name), 1)
                this.setState({
                    androidCert: androidCert
                })

            } else if (certObj == 'iosCert') {
                iosCert.splice(iosCert.findIndex(file => file.name === fileObj.name), 1)
                this.setState({
                    iosCert: iosCert
                })

            } else if (certObj == 'mtlsCert') {
                mtlsCert.splice(mtlsCert.findIndex(file => file.name === fileObj.name), 1)
                this.setState({
                    mtlsCert: mtlsCert
                })

            }
        } else {
            this.setState({
                deleteFile: value,
                deleteFileObj: fileObj
            })
        }

    }
    async deleteFilefromS3() {
        let { deleteFile, deleteFileObj, prodAppType } = this.state
        if (deleteFile && prodAppType != 'P') {
            removeSignersCertFile(deleteFileObj)
            this.togglePopup(false)
            this.componentWillMount()
        }

    }
    async submitSignerDetails(event, status) {
        event.preventDefault();
        this.setState({
            fetched : false
        })
        let { selectedEnv, androidBundleID, iosBundleID, appFunctionality, useBase,
            businessNature, androidCert, iosCert, mtlsCert, appSubmitted, noAndroidCert, noIosCert, noCertReasonInput } = this.state
        let applicationType = 0 //fresh Application
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        if (!application) {

            navigate('/product/netsclick/application')
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }

        let netsclickAppID = application.nc_app_id
        if (appSubmitted) {
            applicationType = 1
        }
        androidBundleID = noAndroidCert ? ' ' : androidBundleID
        iosBundleID = noIosCert ? ' ' : iosBundleID

        if ((!androidBundleID || !iosBundleID || !appFunctionality || !useBase || !businessNature) && status == 'P') {
            this.setState({
                submitted: true,
                fetched : true
            })
            return
        }
        if ((noAndroidCert || noIosCert) && !noCertReasonInput) {
            this.setState({
                submitted: true,
                fetched : true
            })
            return
        }
        androidCert = androidCert.length == 0 ? null : androidCert
        iosCert = iosCert.length == 0 ? null : iosCert
        mtlsCert = mtlsCert.length == 0 ? null : mtlsCert

        if (((!androidCert && !noAndroidCert) || (!iosCert && !noIosCert) || !mtlsCert) && status == 'P') {
            this.setState({
                submitted: true,
                fetched : true
            })
            return
        }
        let inputObj = {
            email: this.state.email,
            fileType: "Signers",
            env: selectedEnv,
            ncAppID: netsclickAppID,
            fileStatus: status

        }
        let certFiles = []
        if (androidCert) {
            for (let i = 0; i < androidCert.length; i++) {
                if (androidCert[i].temp) {
                    certFiles.push({ key: "androidCert", name: androidCert[i].name, value: androidCert[i] })
                }

            }

        }
        if (iosCert) {
            for (let j = 0; j < iosCert.length; j++) {
                if (iosCert[j].temp) {
                    certFiles.push({ key: "iosCert", name: iosCert[j].name, value: iosCert[j] })
                }
            }

        }
        if (mtlsCert) {
            for (let k = 0; k < mtlsCert.length; k++) {
                if (mtlsCert[k].temp) {
                    certFiles.push({ key: "mtlsCert", name: mtlsCert[k].name, value: mtlsCert[k] })
                }
            }
        }
        for (let x = 0; x < certFiles.length; x++) {
            await UploadDocstoS3(inputObj, certFiles[x])
        }

        let signersDetailsObj = {
            email: this.state.email,
            env: selectedEnv,
            reqID: netsclickAppID,
            androidPkg: androidBundleID,
            iosPkg: iosBundleID,
            useBase: useBase,
            functionApp: appFunctionality,
            businessType: businessNature,
            liveDate: null,
            noAndroidCert: noAndroidCert,
            noIosCert: noIosCert,
            type: applicationType,
            appStatus: status,
            noCertReason: noCertReasonInput
        }
        await submitSignerDetailsInfo(signersDetailsObj)
        this.setState({
            androidCert: null,
            iosCert: null,
            mtlsCert: null,
            fetched : true
        })

        this.redirectPage('overview')
    }
    async componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        const application = isLoggedIn() ? JSON.parse(window.localStorage.getItem('ncApp')) : null;
        if (!application) {

            navigate('/product/netsclick/application')
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (application) {

            let data = await getIntegrationStatus({
                email: application.email,
                netsclickAppID: application.nc_app_id
            })

            let filesInfo = await getSignerInfo({
                netsclickAppID: application.nc_app_id,
                env: this.state.selectedEnv,
                fileRef: "Signers"
            })
            let androidCert = filesInfo.filter(file => file.FILE_MODULE == 'androidCert')
            let iosCert = filesInfo.filter(file => file.FILE_MODULE == "iosCert")
            let mtlsCert = filesInfo.filter(file => file.FILE_MODULE == "mtlsCert")

            this.setState({
                androidCert: androidCert,
                iosCert: iosCert,
                mtlsCert: mtlsCert
            })
            let prodCredentialsInfo = data && data.length >= 1 ? data[1] : null

            prodCredentialsInfo = prodCredentialsInfo ? prodCredentialsInfo.filter(obj => obj.ENV_PROFILE == ENV_PROD) : null
            prodCredentialsInfo = prodCredentialsInfo && prodCredentialsInfo.length >= 0 ? prodCredentialsInfo[0] : null

            if (prodCredentialsInfo && prodCredentialsInfo.ENV_PROFILE == ENV_PROD) {

                this.setState({
                    androidBundleID: prodCredentialsInfo.ANDROID_PACKAGE,
                    iosBundleID: prodCredentialsInfo.IOS_PACKAGE,
                    appFunctionality: prodCredentialsInfo.APP_FUNCTIONALITY,
                    businessNature: prodCredentialsInfo.BUSINESS_NATURE,
                    useBase: prodCredentialsInfo.USER_BASE,
                    prodAppStatus: prodCredentialsInfo.STATUS,
                    prodAppType: prodCredentialsInfo.APP_TYPE,
                    appSubmitted: true,
                    tempValueExpiryDate: prodCredentialsInfo.EXPIRED_AT,
                    noAndroidCert: prodCredentialsInfo.NO_ANDROID_CERT,
                    noIosCert: prodCredentialsInfo.NO_IOS_CERT,
                    profileSavedTime: prodCredentialsInfo.UPDATED_AT,
                    noCertReasonInput: prodCredentialsInfo.REMARKS
                })
            }
        }

    }

    render() {
        let { fetched, androidBundleID, iosBundleID, useBase, appFunctionality, submitted, businessNature, signerStatus,
            profileSavedTime, noCertReasonInput  } = this.state
        if (!fetched) {
            return <div className="loading"></div>
        }
        that = this
        return (
            <Layout>
                {
                    this.state.CertExpiryInDays > 90 && this.state.CertExpiryInDays <= 180 && (
                        <Modal isOpen={true}>
                            <ModalHeader><b>Certificate Expiry</b></ModalHeader>
                            <ModalBody>
                                You have uploaded a cert that is going to expire in 6 months. <br />
                                We recommend that you upload a cert that has longer validity to minimize disruption due to cert renewal.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => this.certExpiryAction("validCert")}>
                                    <b>Upload with this cert</b>
                                </Button>{' '}
                                <Button color="secondary" onClick={() => this.certExpiryAction("invalidCert")}>
                                    <b>Upload new cert</b>
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                }


                {
                    this.state.CertExpiryInDays > 0 && this.state.CertExpiryInDays <= 90 && (
                        <Modal isOpen={true}>
                            <ModalHeader><b>Invalid Certificate</b></ModalHeader>
                            <ModalBody>
                                You have uploaded a cert that is going to expire in 3 months.<br/>
                                Please upload a new one that has a validity longer than 3 months
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => this.certExpiryAction("expiredCert")}>
                                    <b>Upload new cert</b>
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                }

                {
                    this.state.showExpiredCertModal && (
                        <Modal isOpen={true}>
                            <ModalHeader><b>Expired Certificate</b></ModalHeader>
                            <ModalBody>
                                The uploaded certificate is already expired and is not accepted. <br />
                                Please upload a new certificate.
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={() => this.certExpiryAction('expiredCert')}>
                                    <b>Upload new cert</b>
                                </Button>
                            </ModalFooter>
                        </Modal>
                    )
                }
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>
                    <Row>
                        <Col>
                            <div><b>NETS Click Integration - PROD setup/credentials</b></div>
                            <div><b>Last Saved : {profileSavedTime ? moment(profileSavedTime).format('DD/MM/YYYY') : ''}</b></div>
                        </Col>
                        <Col sm={3}>
                            <Merchantcard /></Col>
                    </Row>
                </div>
                <FormGroup row >
                    <Col sm={2}>
                        <LeftNavBar menu='prodSetupCredentials' />
                    </Col>
                    <Col sm={7}>

                        <Row className='centerAlignedMenu'><div>
                            <Button
                                color="primary"
                                outline
                                active
                            >
                                <b>Pre-requisites</b>{' '}
                                <Badge>
                                    (Merchant to Provide)
                                </Badge>
                            </Button>
                        </div>
                            <div>{'    '}</div>
                            <div>
                                <Button
                                    color="primary"
                                    outline
                                    href="/product/netsclick/downloadProdcredentials"
                                >
                                    <b>Credentials</b> {' '}
                                    <Badge>
                                        (Merchant to Download)
                                    </Badge>
                                </Button>
                            </div></Row>
                        <div className='topAligned'>
                            <Form class="common-left-align-2">
                                <FormGroup row>
                                    <Label
                                        for="exampleEmail"
                                        size="lg"
                                        sm={4}
                                    >
                                        <b> Android Package Name / Bundle ID</b>
                                    </Label>
                                    <Col sm={5}>
                                        <Input
                                            bsSize="lg"
                                            id="androidBundleID"
                                            onChange={this.assignValue}
                                            valid={androidBundleID}
                                            invalid={!androidBundleID && submitted  && !this.state.noAndroidCert }
                                            value={androidBundleID}
                                            disabled={this.state.prodAppType == 'P' || this.state.noAndroidCert }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="exampleEmail"
                                        size="lg"
                                        sm={4}
                                    >
                                        <b> IOS Package Name / Bundle ID</b>
                                    </Label>
                                    <Col sm={5}>
                                        <Input
                                            bsSize="lg"
                                            id="iosBundleID"
                                            onChange={this.assignValue}
                                            valid={iosBundleID}
                                            invalid={!iosBundleID && submitted && !this.state.noIosCert }
                                            value={iosBundleID}
                                            disabled={this.state.prodAppType == 'P'  || this.state.noIosCert }
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="exampleEmail"
                                        size="lg"
                                        sm={4}
                                    >
                                        <b> Nature of Business</b>
                                    </Label>
                                    <Col sm={5}>
                                        <Input
                                            bsSize="lg"
                                            id="businessNature"
                                            onChange={this.assignValue}
                                            valid={businessNature}
                                            invalid={!businessNature && submitted}
                                            value={businessNature}
                                            disabled={this.state.prodAppType == 'P'}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="exampleEmail"
                                        size="lg"
                                        sm={4}
                                    >
                                        <b> Functionality of App</b>
                                    </Label>
                                    <Col sm={5}>
                                        <Input
                                            bsSize="lg"
                                            id="appFunctionality"
                                            onChange={this.assignValue}
                                            valid={appFunctionality}
                                            invalid={!appFunctionality && submitted}
                                            value={appFunctionality}
                                            disabled={this.state.prodAppType == 'P'}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                        for="exampleEmail"
                                        size="lg"
                                        sm={4}
                                    >
                                        <b>Use Base</b>
                                    </Label>
                                    <Col sm={5}>
                                        <Input
                                            bsSize="lg"
                                            id="useBase"
                                            onChange={this.assignValue}
                                            valid={useBase}
                                            invalid={!useBase && submitted}
                                            value={useBase}
                                            disabled={this.state.prodAppType == 'P'}
                                        />
                                    </Col>
                                </FormGroup>


                                <div className='row'>
                                    <div>
                                        <Row>
                                            <Col sm={5}>
                                                <Col><div className='topMidAligned'>Merchant Signer Certificate  (Android)<div><small>[for vKey asset generation]</small></div></div></Col>
                                            </Col>

                                            <Col>
                                                <Row>{this.state.noAndroidCert ? '' : <FileUpload disabled={this.state.prodAppType == 'P'} id='androidCert' />}


                                                    <div className='topAlignedMedium' >{this.state.androidCert && this.state.androidCert.length > 0 ? this.state.androidCert.map(file => <div>{file.FILE_NAME ? file.FILE_NAME : file.name}{'    '} <small onClick={() => this.togglePopup(true, file, "androidCert")}>&#10060;</small><br/><small> ( Expires on {moment(file.EXP_DATE ? file.EXP_DATE : file.expiryDate).format('DD-MM-YYYY')} ) </small><br/> </div>) : <Label check className="leftAlignedMedium topAlignedMedium" id="androidCert" >
                                                        {
                                                            this.state.noIosCert ? '' : <div className='oneLineAlignment'> <Input className="reverseTopAlignment" onChange={() => this.setIntOsType("androidCert")} type="checkbox" checked={this.state.noAndroidCert ? true : false} />{''}
                                                                <b>No Android App. {this.state.noAndroidCert ? <Input placeholder='Reason :_______' id="noCertReasonInput" valid={noCertReasonInput} invalid={!noCertReasonInput && submitted} onChange={this.assignValue} value={noCertReasonInput}> </Input> : ''} </b></div>
                                                        }
                                                    </Label>}</div></Row>
                                                <div className='font-size-small'>For prod, if app is signed by Google, please include Google signing key & upload key </div>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col sm={5}>
                                                <Col><div className='topMidAligned'>Merchant Signer Certificate  (IOS)<div><small>[for vKey asset generation]</small></div></div></Col>
                                            </Col>
                                            <Col>
                                                <Row>{this.state.noIosCert ? '' : <FileUpload disabled={this.state.prodAppType == 'P'} id='iosCert' />}

                                                    <div className='topAlignedMedium' >{this.state.iosCert && this.state.iosCert.length > 0 ? this.state.iosCert.map(file => <div>{file.FILE_NAME ? file.FILE_NAME : file.name}{'    '} <small onClick={() => this.togglePopup(true, file, "iosCert")}>&#10060;</small> <br/><small> ( Expires on {moment(file.EXP_DATE ? file.EXP_DATE : file.expiryDate).format('DD-MM-YYYY')} ) </small><br/></div>) : <Label check className="leftAlignedMedium topAlignedMedium" id="iosCert" >
                                                        {
                                                            this.state.noAndroidCert ? '' : <div className='oneLineAlignment'> <Input className="reverseTopAlignment" onChange={() => this.setIntOsType("iosCert")} type="checkbox" checked={this.state.noIosCert ? true : false} />{''}
                                                                <b>No IOS App. {this.state.noIosCert ? <Input placeholder='Reason :_______' id="noCertReasonInput" onChange={this.assignValue} valid={noCertReasonInput} invalid={!noCertReasonInput && submitted} value={noCertReasonInput}> </Input> : ''} </b></div>
                                                        }
                                                    </Label>}</div></Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={5}><div className='topMidAligned'>Commercial CA Certificate <br />[for mTLS setup]</div>
                                            </Col>
                                            <Col>
                                                <Row><FileUpload id='mtlsCert' /> <div className='topAlignedMedium' >{this.state.mtlsCert && this.state.mtlsCert.map(file => <div>{file.FILE_NAME ? file.FILE_NAME : file.name}{'    '} <small onClick={(e) => this.togglePopup(true, file, "mtlsCert")}>&#10060;</small><br/><small> ( Expires on {moment(file.EXP_DATE ? file.EXP_DATE : file.expiryDate).format('DD-MM-YYYY')} ) </small><br/> </div>)}</div></Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <Row>
                                    {
                                        submitted && (!this.state.allFilesUploaded) ? <Fade className='common-top-align leftAligned errorMessage'>Please fill all the mandatory fields and upload cert's</Fade> : null
                                    }
                                    {this.state.fileUploadError ? <Fade className='common-top-align leftAligned errorMessage'><b>{errorConstants[this.state.displayErrorCode]}</b></Fade> : null}
                                </Row>
                                <div className='certExtractionPane'> <div className='certExtractionSubPane'>
                                    <Label><b>Guide for cert extraction</b></Label>
                                    <Row><Col><div >Signer cert extraction &nbsp; <a href={"/assets/netsclick/signer_cert_extraction/signer_cert_extraction.pdf"} download><img className='topAligned image-min-width' src='/assets/netsclick/images/download-pdf.jpg' /></a></div>
                                    </Col><Col>
                                            <div>CA cert extraction &nbsp; <a href={"/assets/netsclick/signer_cert_extraction/ca_cert_extraction.pdf"} download><img className='topAligned image-min-width' src='/assets/netsclick/images/download-pdf.jpg' /></a></div></Col></Row>

                                </div></div>
                                {this.state.prodAppType == 'T' ? <Badge color="warning">This application values are stored temperarory and informed will be removed on {moment(this.state.tempValueExpiryDate).format('DD-MM-YYYY')}</Badge> : ''}
                                {this.state.prodAppType == 'P' ? <Badge color='success'>All pre-requisites are provided. </Badge> : ''}

                                {
                                    this.state.prodAppType != 'P' ?
                                        <div className='topAlignedMedium'>
                                            <div className='leftMenu'><Button color='link' onClick={e => this.submitSignerDetails(e, 'T')}
                                                disabled={signerStatus == 'A'}><u className='leftAligned'>Save & Submit Later</u></Button><div>
                                                    ( Draft will be saved 5 days )
                                                </div></div>
                                            <Button className='topAligned rightMenu'
                                                color="primary"
                                                onClick={e => this.submitSignerDetails(e, 'P')}
                                                disabled={signerStatus == 'A'}

                                            >
                                                Submit/ Update
                                            </Button>
                                        </div> : ''
                                }
                            </Form>
                        </div >
                    </Col>
                </FormGroup>
                <Query />
                <div>

                    <Modal isOpen={this.state.deleteFile && this.state.prodAppType != 'P'} centered={true}>
                        <ModalBody>
                            Are you sure you want to delete this item?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={(e) => this.deleteFilefromS3()}>
                                Delete
                            </Button>{' '}
                            <Button color="secondary" onClick={(e) => this.togglePopup(false)}>
                                Cancel
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </Layout>
        )
    }

}


const FileUpload = ({ title = '', id }) => {

    return (
        <Col sm="5" onClick={event => event.preventDefault()}>
            <Label className='common-left-align'><b>{title}</b></Label>
            <Dropzone onDrop={(acceptedFiles, fileRejections) => { that.onDrop(id, acceptedFiles, fileRejections) }} maxSize={3145728}>

                {({ getRootProps, getInputProps }) => {

                    return (
                        <section className="container">
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Card>
                                    <img className='centerImage' src="/assets/netsclick/images/upload.jpg" />
                                    <div className='centered'><font size="1"> &nbsp;&nbsp;Drag and drop Files Here to Upload</font></div>
                                    <Button color="primary" size="sm"><small>Click here to upload</small></Button>
                                </Card>
                            </div>
                        </section>
                    )
                }}
            </Dropzone>
        </Col>
    )
}